import Vue from "vue";
import Router from "vue-router";
import store from '@/store/store.js'
Vue.use(store)

//--------------------------------------------------------------------------
//CONTAINERS
//--------------------------------------------------------------------------
const TheContainer = () => import("@/components/TheContainer");
const WebAppContainer = () => import("@/components/WebAppContainer.vue");


//--------------------------------------------------------------------------
//WEBSITE
//--------------------------------------------------------------------------

const blog = () => import("@/views/Blog/Blog.vue");

const homepage = () => import("@/views/Home.vue");
const download = () => import("@/views/Download.vue");
const about = () => import("@/views/About.vue");
const contact = () => import("@/views/Contact.vue");
const policy = () => import("@/views/Policy.vue");
const privacy = () => import("@/views/Privacy.vue");
const Page404 = () => import("@/views/Pages/Page404.vue");


const main = () => import("@/views/User.vue");
const SinglePost = () => import("@/views/SinglePost.vue");
const SingleQuestion = () => import("@/views/SingleQuestion.vue");

Vue.use(Router);

let router = new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      redirect: "/homepage",
      name: "",
      meta: { title: null },
      component: TheContainer,
      children: [
        {
          path: "/",
          name: "homepage",
          component: homepage,
          meta: { title: "صفحه اصلی" },
        },
        {
          path: "blog",
          name: "blog",
          component: blog,
          meta: { title: "بلاگ" },
        },
        {
          path: "download",
          name: "download",
          component: download,
          meta: { title: "دانلود نرم افزار" },
        },
        {
          path: "about-us",
          name: "about",
          component: about,
          meta: { title: "درباره ما" },
        },
        {
          path: "contact-us",
          name: "contact",
          component: contact,
          meta: { title: "تماس با ما" },
        },
        {
          path: "policy",
          name: "policy",
          // redirect:"/privacy",
          component: policy,
          meta: { title: "Policy" },
        },
        {
          path: "privacy",
          name: "privacy",
          component: privacy,
          meta: { title: "Privacy" },
        },
      ],
    },
    {
      path: "/:username",
      redirect: "/:username",
      component: WebAppContainer,
      children: [
        {
          path: "/:username/question/:id",
          component: SingleQuestion,
          name: "SingleQuestion",
          meta: { title: "" },
        },
        {
          path: "/:username/:type/:id",
          component: SinglePost,
          name: "SinglePost",
          meta: { title: "" },
        },
        {
          path: "/:username",
          component: main,
          name: "main",
          meta: { title: "" },
        },
      ],
    },
    {
      // matches everything else
      path: "/:pathMatch(.*)*",
      name: "notFound",
      component: Page404,
      meta: { title: "404" },
    },
    {
      // matches everything else
      path: "*",
      name: "notFounded",
      component: Page404,
      meta: { title: "404" },
    },
  ],
});

const DEFAULT_TITLE = "مایندولوژی";
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    if(to.meta.title != "") {
      document.title = to.meta.title + " | مایندولوژی" || DEFAULT_TITLE;
    }
    else {
      document.title = "مایندولوژی" || DEFAULT_TITLE;
    }
  });
});

// router.beforeEach((to, from, next) => {
  // if (to.meta.requiresAuth  && !store.getters.isLoggedIn) next({ name: 'Auth' })
  // else next()
// });


//   router.beforeResolve((to, from, next) => {
//     if (to.name) {
//       NProgress.start();
//     }
//     next();
//   });

//   router.afterEach((to, from) => {
//     NProgress.done();
//   });

//   router.beforeEach((to, from, next) => {
//     if (to.matched.some((record) => record.meta.requiresAuth)) {
//       if (store.getters.isLoggedIn) {
//         next();
//         return;
//       }
//       next({ name: "Login" });
//     } else {
//       next();
//     }
//   });

// router.beforeEach((to, from, next) => {
//   if (to.meta.requiresAuth && !store.state.auth) next("/login");
//   else next();
// });

export default router;

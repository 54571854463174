// en, de, fr, pt
export default {
  fa: {
    'Hello!' : "سلام!",
    'Search...' : "جستجو...",
    'Home' : "صفحه اصلی",
    'Blog' : "بلاگ",
    'About' : "درباره ما",
    'Contact' : "تماس با ما",
    'Login' : "ورود",
    'Log out' : "خروج",
    'WebApp' : "وب اپ",
    
    'Explore' : "اکسپلور",
    'Search' : "جستجو",
    'Mood status' : "وضعیت روحی",
    'Profile' : "پروفایل",

  },
  en: {}
};

const getters = {
  isLoggedIn: (state) => !!state.token,
  authStatus: (state) => state.status,
  authenticated: (state) => state.authenticated,

  notificationActive: (state) => state.notificationActive,
  threeDotsActive: (state) => state.threeDotsActive,
  helloActive: (state) => state.helloActive,
  toolbarTitle: (state) => state.toolbarTitle,
  toolbarTitleActive: (state) => state.toolbarTitleActive,
  fullName: (state) => state.fullName,
  notificationCount: (state) => state.notificationCount,
  
  mobileNumber: (state) => state.mobileNumber,
  clientType: (state) => state.clientType,
  userId: (state) => state.userId,
  profileFilled: (state) => state.profileFilled,
  clinicId: (state) => state.clinicId,
};
export default getters;

import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import fa from 'vuetify/src/locale/fa.ts'
import en from 'vuetify/src/locale/en.ts'

Vue.use(Vuetify);

const opts = {
  rtl: true,
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
        primary: "#20B1EA", // #FFCDD2
        secondary: "#2A3465", // #E53935
        accent: "#FF5252", // #3F51B5
      },
    },
  },
  lang: {
    locales: { fa, en },
    current: 'fa',
  },
};

export default new Vuetify(opts);

<template>
  <v-app>
    <router-view></router-view>

    <div class="loading" v-if="isLoading">
      <div class="block">
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    // HelloWorld,
    // Navbar
  },
  computed: {
    ...mapState(["isLoading"]),
  },
  created() {
    this.axios.interceptors.request.use(
      (config) => {
        this.$store.commit("loading", true);
        return config;
      },
      (error) => {
        this.$store.commit("loading", false);
        return Promise.reject(error);
      }
    );

    this.axios.interceptors.response.use(
      (response) => {
        this.$store.commit("loading", false);
        return response;
      },
      (error) => {
        this.$store.commit("loading", false);
        return Promise.reject(error);
      }
    );
  },
};
</script>
<style>
.loading {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.25);
  z-index: 2000000000;
}

.block {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 2rem;
  width: 2rem;
  transform: translateX(-50%) translateY(-50%);
}
.block > .item {
  position: absolute;
  height: 2rem;
  width: 2rem;
  background: #0D47A1;
  animation: move 0.5s linear infinite;
}
.block > .item:nth-of-type(1) {
  top: -2rem;
  left: -2rem;
  animation-delay: 0s;
}
.block > .item:nth-of-type(2) {
  top: -2rem;
  left: 0;
  animation-delay: -0.0625s;
}
.block > .item:nth-of-type(3) {
  top: -2rem;
  left: 2rem;
  animation-delay: -0.125s;
}
.block > .item:nth-of-type(4) {
  top: 0;
  left: 2rem;
  animation-delay: -0.1875s;
}
.block > .item:nth-of-type(5) {
  top: 2rem;
  left: 2rem;
  animation-delay: -0.25s;
}
.block > .item:nth-of-type(6) {
  top: 2rem;
  left: 0;
  animation-delay: -0.3125s;
}
.block > .item:nth-of-type(7) {
  top: 2rem;
  left: -2rem;
  animation-delay: -0.375s;
}
.block > .item:nth-of-type(8) {
  top: 0;
  left: -2rem;
  animation-delay: -0.4375s;
}
@keyframes move {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
</style>

import Vue from "vue";
import App from "@/App";

//------------------------------------------------------------------------------------------
// Vue Router
//------------------------------------------------------------------------------------------
import router from "@/router";

//------------------------------------------------------------------------------------------
// axios
//------------------------------------------------------------------------------------------
import axios from "axios";
import VueAxios from 'vue-axios';
Vue.prototype.$http = axios;
Vue.config.productionTip = false;

Vue.use(VueAxios, axios);

//------------------------------------------------------------------------------------------
// Vuex Store
//------------------------------------------------------------------------------------------
import store from "@/store/store";

//------------------------------------------------------------------------------------------
// i18n
//------------------------------------------------------------------------------------------
import i18n from '@/plugins/i18n/i18n'

//------------------------------------------------------------------------------------------
// VeeValidate
//------------------------------------------------------------------------------------------
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate, {
  i18n,
});

//------------------------------------------------------------------------------------------
// JQuery
//------------------------------------------------------------------------------------------
import jQuery from "jquery";
Vue.use({
  install(V) {
    V.$jQuery = V.prototype.$jQuery = jQuery;
  },
});

//------------------------------------------------------------------------------------------
// vuetify
//------------------------------------------------------------------------------------------
import vuetify from "@/plugins/vuetify.js"; // path to vuetify export
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

//------------------------------------------------------------------------------------------
// Import Bootstrap an BootstrapVue CSS files (order is important)
//------------------------------------------------------------------------------------------
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/css/bootstrap-custom.scss";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

//------------------------------------------------------------------------------------------
// Jalaali moment
//------------------------------------------------------------------------------------------
Vue.use(require("vue-moment-jalaali"));

//------------------------------------------------------------------------------------------
// Map
//------------------------------------------------------------------------------------------
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);

//------------------------------------------------------------------------------------------
// OWL Carousel
//------------------------------------------------------------------------------------------
import carousel from "vue-owl-carousel";
Vue.component("carousel", carousel);

//------------------------------------------------------------------------------------------
// Toast
//------------------------------------------------------------------------------------------
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
const toastOptions = {
  position: POSITION.BOTTOM_RIGHT,
  timeout: 2000,
};
Vue.use(Toast, toastOptions);

//------------------------------------------------------------------------------------------
// Sweet alert
//------------------------------------------------------------------------------------------
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const options = {
  confirmButtonColor: '#20B1EA',
  cancelButtonColor: '#546E7A',
  denyButtonColor: '#D32F2F',
};

Vue.use(VueSweetalert2, options);

//------------------------------------------------------------------------------------------
// Reset CSS
//------------------------------------------------------------------------------------------
import "ress/dist/ress.min.css";
import "normalize.css";
import "./assets/css/custom.css";


new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
